import React, { useState } from "react"
import Layout from "components/myBookShelf/Layout.js"
import MyBookShelfContainer from "../containers/myBookShelfContainer"
import { MINE } from "utils/data"
import queryString from "query-string"
const MyBookshelf = props => {
  const { tileName, page } = queryString.parse(props.location.search)

  console.log(tileName)
  const [title, setTitle] = useState(tileName)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  return (
    <Layout title={title} setTitle={setTitle} setCurrentPage={setCurrentPage}>
      <div>
        <MyBookShelfContainer
          title={title}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default MyBookshelf
