import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./myBookShelfContainer.module.css"
import OrderMenu from "components/tools/orderMenu"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Pagenation from "components/tools/pagination.js"
import {
  getStoryRecentAsync,
  getStoryLikeAsync,
  getExhLikeAsync,
  getRecentLessonAsync,
  getLessonLikeAsync,
  getLessonWritedAsync,
  getClassLikedAsync,
  getCardLikedAsync,
  getMediaLikedAsync,
} from "store/modules/myShelfReducer.js"
import { permission } from "utils"
import { MINE } from "utils/data"
const MyBookShelfContainer = props => {
  const dispatch = useDispatch()

  const [order, setOrder] = useState(1)

  const { currentPage, setCurrentPage } = props

  const [pagePostSize, setPagePostSize] = useState(0)

  const getStoryRecent = useCallback(
    () => dispatch(getStoryRecentAsync()),
    [dispatch]
  )
  const getStoryLike = useCallback(
    (page, take, order, table) =>
      dispatch(getStoryLikeAsync(page, take, order, table)),
    [dispatch]
  )
  const getExhLike = useCallback(
    (page, take, order, table) =>
      dispatch(getExhLikeAsync(page, take, order, table)),
    [dispatch]
  )
  const getLessonLike = useCallback(
    (page, take, order, table) =>
      dispatch(getLessonLikeAsync(page, take, order, table)),
    [dispatch]
  )
  const getLessonRecent = useCallback(
    () => dispatch(getRecentLessonAsync()),
    [dispatch]
  )
  const getWritetLesson = useCallback(
    (page, take, order) => dispatch(getLessonWritedAsync(page, take, order)),
    [dispatch]
  )
  const getClassLike = useCallback((page, take, order, table) =>
    dispatch(getClassLikedAsync(page, take, order, table), [dispatch])
  )
  const getCardLiked = useCallback((page, take, order, table) =>
    dispatch(getCardLikedAsync(page, take, order, table), [dispatch])
  )
  const getMediaLike = useCallback((page, take, order, table) =>
    dispatch(getMediaLikedAsync(page, take, order, table), [dispatch])
  )

  const myShelfReducer = useSelector(
    ({ myShelfReducer }) => myShelfReducer,
    shallowEqual
  )

  console.log(props.title)

  useEffect(() => {
    switch (props.title) {
      case MINE.STORY.recent:
        getStoryRecent()
        break
      case MINE.STORY.like:
        setPagePostSize(16)
        getStoryLike(currentPage, 16, order, 4)
        break
      case MINE.STORY.exhLike:
        setPagePostSize(7)
        getExhLike(currentPage, 7, order, 7)
        break
      case MINE.CLASS.recent:
        getLessonRecent()
        break
      case MINE.CLASS.like:
        setPagePostSize(16)
        getLessonLike(currentPage, 16, order, 8)
        break
      case MINE.CLASS.writed:
        setPagePostSize(16)
        getWritetLesson(currentPage, 16, order)
        break
      case MINE.CLASS.roomLike:
        setPagePostSize(16)
        getClassLike(currentPage, 16, order, 9)
        break
      case MINE.VIS.likeCard:
        setPagePostSize(7)
        getCardLiked(currentPage, 7, order, 5)
        break
      case MINE.VIS.likeMedia:
        setPagePostSize(7)
        getMediaLike(currentPage, 15, order, 6)
        break
    }
  }, [order, currentPage, props])

  return (
    <div className={styles.con}>
      <div className={"title"}>
        <p className={`subtitle`}>{props.title}</p>
        <div style={{ marginTop: "12px" }}>
          (
          <p className={`red subnotice`}>
            {myShelfReducer[props.title]?.list?.length ?? 0}{" "}
          </p>
          개
          {props.title
            ? props.title.split(" ")[props.title.split(" ").length - 1]
            : null}
          )
        </div>
      </div>
      <div className={styles.chooseBox}>
        {props.title === MINE.STORY.recent ||
        props.title === MINE.CLASS.recent ||
        props.title === MINE.VIS.likeCard ? null : (
          <OrderMenu
            order={order}
            setOrder={setOrder}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              width: "1100px",
            }}
            setCurrentPage={setCurrentPage}
          />
        )}
        {props.title === MINE.CLASS.recent ||
        props.title === MINE.CLASS.like ||
        props.title === MINE.CLASS.writed ? (
          <div
            className={`greenBtn ${styles.write}`}
            onClick={() => permission("/class/lesson/write/")}
          >
            수업나눔 작성하기
          </div>
        ) : null}
      </div>
      <div className={styles.box}>
        <div className={`colContainer ${styles.main}`}>
          {myShelfReducer[props.title]?.list}
        </div>
      </div>
      {props.title === MINE.STORY.recent ||
      props.title === MINE.CLASS.recent ||
      myShelfReducer[props.title]?.list?.length <= 0 ? null : (
        <Pagenation
          total={myShelfReducer[props.title]?.total ?? pagePostSize}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  )
}

export default MyBookShelfContainer
