import React, { useState, useEffect } from "react"
import * as styles from "./layout.module.css"
import Header from "./header"
import Footer from "components/common/footer"

const Layout = props => {
  //headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
  return (
    <div>
      <Header
        setTitle={props.setTitle}
        title={props.title}
        setCurrentPage={props.setCurrentPage}
      />

      <div>
        <div>{props.children}</div>
      </div>

      <Footer />
    </div>
  )
}

export default Layout
