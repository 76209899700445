import React, { useState, useEffect, useRef } from "react"
import * as styles from "./header.module.css"
import Logo from "components/tools/logo.js"
import SearchContainer from "containers/searchContainer"
import { navigate } from "gatsby"
import { getJson } from "utils"
import { profiles } from "utils/data.js"
import Menu from "components/menu"
import { useMenu } from "hooks"
import { MINE } from "utils/data"
import { getRank } from "utils"
import { rank as memberRank } from "utils/data"
import { shallowEqual, useSelector } from "react-redux"
const Header = props => {
  //headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
  const [profile] = useState(getJson("profile"))
  const [inBubble, setInBubble] = useState(null)

  const [, setMenu, onMenu] = useMenu()
  const [, setSubMenu, onSubMenu] = useMenu()
  const alarms = useSelector(
    ({ alarmReducer: { alarm } }) => alarm,
    shallowEqual
  )
  const alarmList = alarms.list.length
  // props.title

  useEffect(() => {
    if (!props.title) {
      if (!inBubble) {
        document.getElementById("story").click()
      } else {
        document
          .getElementsByClassName(styles.classCon)[0]
          .childNodes[0].click()
      }
    }
  }, [inBubble])

  const onMain = ({ currentTarget }) => {
    setMenu(currentTarget)

    setInBubble(subMenuEle[currentTarget.getAttribute("id")])

    onMenu(curr => {
      curr.style.textDecoration = "underline"
      curr.style.textUnderlinePosition = "under"

      return prev => {
        prev.style.textDecoration = "none"
      }
    })
  }

  const onSub = ({ currentTarget }, menuName) => {
    setSubMenu(currentTarget)
    console.log(menuName)
    props.setTitle(menuName)
    props.setCurrentPage(1)
    onSubMenu(curr => {
      curr.style.color = "#e95c63"

      return prev => {
        prev.style.color = "#333"
      }
    })
  }

  const subMenuEle = {
    story: (
      <div className={styles.classCon} key={"story"} id={"story"}>
        <div onClick={e => onSub(e, MINE.STORY.recent)}>
          {MINE.STORY.recent}
        </div>
        <div onClick={e => onSub(e, MINE.STORY.like)}>{MINE.STORY.like}</div>
        <div onClick={e => onSub(e, MINE.STORY.exhLike)}>
          {MINE.STORY.exhLike}
        </div>
      </div>
    ),
    class: (
      <div className={styles.classCon} id={"class"} key={"class"}>
        <div onClick={e => onSub(e, MINE.CLASS.recent)}>
          {MINE.CLASS.recent}
        </div>
        <div onClick={e => onSub(e, MINE.CLASS.like)}>{MINE.CLASS.like}</div>
        <div onClick={e => onSub(e, MINE.CLASS.writed)}>
          {MINE.CLASS.writed}
        </div>
        <div onClick={e => onSub(e, MINE.CLASS.roomLike)}>
          {MINE.CLASS.roomLike}
        </div>
      </div>
    ),
    visible: (
      <div className={styles.classCon} id={"visible"} key={"visible"}>
        <div onClick={e => onSub(e, MINE.VIS.likeCard)}>
          {MINE.VIS.likeCard}
        </div>
        <div onClick={e => onSub(e, MINE.VIS.likeMedia)}>
          {MINE.VIS.likeMedia}
        </div>
      </div>
    ),
  }

  return (
    <>
      {props.settingHeader ? (
        <div className={styles.con} style={{ margin: 0 }}>
          <div>
            <div onClick={() => navigate(`/front`)}>
              <Logo w={121.94} h={25.85} white={true} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.con} style={{ margin: 0 }}>
          <div>
            <div className={styles.head}>
              <div onClick={() => navigate(`/front`)}>
                <Logo w={121.94} h={25.85} white={true} />
              </div>

              <div>
                <SearchContainer white={true} />
              </div>

              <div>
                <Menu
                  settingStyle={{
                    width: "86px",
                    padding: "10px",
                    left: getRank() === memberRank.NORMAL ? "241px" : "167px",
                    top: "35px",
                  }}
                  alarmStyle={{
                    width: "297px",
                    height: "709px",
                    padding: "0px",
                    right: "-20px",
                    top: "35px",
                    height: `${alarmList * 102.5}px`,
                    maxHeight: "709px",
                  }}
                />
              </div>
            </div>

            <div className={styles.footer}>
              <div>
                <div>
                  <img
                    src={profiles.get(profile?.icon)}
                    className={styles.LoginImg}
                  />
                </div>

                <div style={{ marginLeft: "119px" }}>
                  {profile?.name}님의 서재
                </div>
              </div>
              <div style={{ marginLeft: "170px" }}>
                <button id="story" onClick={onMain}>
                  스토리
                </button>

                <span>|</span>

                <button id="class" onClick={onMain}>
                  클래스
                </button>
                <span>|</span>
                <button id="visible" onClick={onMain}>
                  시각자료
                </button>
              </div>
            </div>
          </div>
          {inBubble ? (
            <div className={styles.menuFooter} style={{ display: "block" }}>
              {inBubble}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default Header
